<router>
{
    name: 'Sales Tool Labor Types',
}
</router>
<template lang="pug">
v-container(fluid)
    v-row
        v-col(cols='12')
            LaborTypes

    //- v-btn(primary, @click="$auth.logout('aad')") Sign out
</template>
<script>

import LaborTypes from '@/components/salestool/labortypes.vue';
export default {
    meta: {
        role: 'satlabortypes'
    },
    components: {
        LaborTypes
    },
    data () {
        return {
            cards: [
                { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 12 },
                { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 6 },
                { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 6 },
            ],
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {
        window.satlabortypesvm = this;
    }
};
</script>

<style scoped>
</style>
