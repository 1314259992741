<template lang="pug">
.laborTypes
    v-container
        v-tabs(v-model='currentTab', color='brand', light)
            v-tab Labor Types
            v-tab Labor Categories
        v-tabs-items(v-model='currentTab', light)
            v-tab-item
                v-data-table(:headers='headers', :items='laborTypes', hide-default-footer, disable-pagination)
                    template(v-slot:item.category='{item, value}')
                        span {{ getCategory(item.laborCategoryId) }}
                    template(v-slot:item.edit='{item, value}')
                        v-btn(icon, color='brand', @click='editLaborType(item)')
                            v-icon edit
                    template(v-slot:item.deleted='{item, value}')
                        v-btn(color='red', icon, @click='restoreItem(item)', v-if='!item.active')
                            v-icon restore_from_trash
                    v-overlay(absolute, :value='busy')
                        .text-h6 Please Wait...
                        v-progress-linear(indeterminate)
                v-row
                    v-col.ml-4
                        v-btn.white--text(color='green', @click='showNewTypeModal=true') Add Labor Type
                        v-switch(v-model='showDeleted', label='Show Deleted Types', color='warning')
            v-tab-item
                v-data-table(:items='laborCategories', :headers='categoryHeaders', dense, hide-default-footer, disable-pagination)
                    template(v-slot:item.edit='{item, value}')
                        v-btn(icon, color='brand', @click='editCategory(item)')
                            v-icon edit
                    template(v-slot:item.deleted='{item, value}')
                        v-btn(color='red', icon, @click='restoreCategory(item)', v-if='!item.active')
                            v-icon restore_from_trash
                v-row
                    v-col.ml-4
                        v-btn.white--text(color='green', @click='showNewCategoryModal=true') Add Labor Category
                        v-switch(v-model='showDeletedCategories', label='Show Deleted Categories', color='warning')

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ message }}
    v-dialog(v-model='showNewTypeModal', max-width='50vw')
        v-card(light)
            v-card-title Add new labor type
            v-card-text
                v-form(ref='form')
                    v-row
                        v-col
                            v-text-field.white--text(v-model='typeCostItem', placeholder='Cost Item', outlined, dense, color='brand', hide-details)
                        v-col
                            v-select(:items='allCategories', item-text='name', item-value='id', color='brand', dense, v-model='typeCategory', outlined, hide-details, label='Category')
                    v-row
                        v-col
                            v-text-field.white--text(v-model='typeName', placeholder='Name', outlined, dense, color='brand', hide-details)
                    v-row
                        v-col
                            v-textarea.white--text(v-model='typeDescription',label='Tasks', outlined, dense, color='brand', hide-details)
            v-card-actions
                v-spacer
                v-btn.white--text(@click='addType', color='green', :loading='addingLaborType') Add Type
    v-dialog(v-model='showNewCategoryModal', max-width='50vw')
        v-card(light)
            v-card-title Add new labor category
            v-card-text
                v-row
                    v-col
                        v-text-field.white--text(v-model='categoryName', placeholder='Name', outlined, color='brand')
                v-row
                    v-col
                        v-btn.white--text(@click='addCategory', color='green') Add Type
    v-dialog(v-model='showEditModal', max-width='700px', v-if='showEditModal')
        v-card(light)
            v-card-title Edit Labor Type
            v-card-text
                v-row
                    v-col
                        v-text-field(v-model='editingType.cost_item', disabled, outlined, label='Cost Item', dense, hide-details, color='brand')
                    v-col
                        v-select(:items='allCategories', item-text='name', item-value='id', color='brand', dense, v-model='editingType.laborCategoryId', outlined, hide-details, label='Category')
                v-row
                    v-col
                        v-text-field(v-model='editingType.name', outlined, label='Name', hide-details, color='brand', dense)
                v-row
                    v-col
                        v-textarea.white--text(v-model='editingType.description',label='Tasks', outlined, dense, hide-details)
            v-card-actions
                v-spacer
                v-btn.white--text(color='red', @click='deleteLaborType') delete
                v-btn(text, @click='cancelLaborTypeEdit') cancel
                v-btn.white--text(color='brand', @click='updateLaborType') save
    v-dialog(v-model='showCategoryEditModal', max-width='500px', v-if='showCategoryEditModal')
        v-card(light)
            v-card-title Edit Labor Category
            v-card-text
                v-row
                    v-col
                        v-text-field(v-model='editingCategory.name', outlined, label='Name', dense, hide-details, color='brand')
            v-card-actions
                v-spacer
                v-btn.white--text(color='red', @click='deleteCategory') delete
                v-btn(text, @click='cancelCategoryEdit') cancel
                v-btn.white--text(color='brand', @click='updateCategory') save
</template>

<script>
import {cloneDeep} from 'lodash';
export default {
    data (){
        return {
            dti,
            message: '',
            busy: false,
            showNewTypeModal: false,
            typeCostItem: '',
            typeCategory: '',
            typeName: '',
            allTypes: [],
            showDeleted: false,
            showDeletedCategories: false,
            showCategoryEditModal: false,
            typeDescription: '',
            editingType: null,
            editingCategory: null,
            originalCategory: null,
            currentTab: 0,
            showNewCategoryModal: false,
            categoryName: '',
            addingLaborType: false,
            showEditModal: false,
            originalLaborType: null,
            allCategories: []
        };
    },
    methods: {
        async updateLaborType () {
            let result = await this.sendCommand({
                action: 'updateLaborType',
                parameters: this.editingType
            });

            if (result) {
                let typeToEdit = this.allTypes.find((t) => {
                    return t.id === result.id;
                });
                if (typeToEdit) {
                    typeToEdit.name = this.editingType.name;
                    typeToEdit.description = this.editingType.description;
                    typeToEdit.laborCategoryId = this.editingType.laborCategoryId;
                }
                this.showEditModal = false;
                this.editingType = null;
                this.message = `"${typeToEdit.name}" updated`;
            } else {
                console.log('error updating labor type');
                this.message = `Error updating "${this.editingType.name}"`;
            }
        },
        async addType () {
            this.addingLaborType = true;
            let newType = cloneDeep(this.allTypes[0]);
            newType.name = this.typeName;
            newType.cost_item = this.typeCostItem;
            newType.description = this.typeDescription;
            newType.laborCategoryId = this.typeCategory;
            newType.id = dti.makeuuid();

            let results = await this.sendCommand({
                action: 'addLaborType',
                parameters: newType
            });
            if (results) {
                this.message = `Added labor type`;
                this.allTypes.push(newType);
            } else {
                this.message = `Failed to add labor type. Ensure Cost Item is unique!`;
            }
            this.addingLaborType = false;
            this.showNewTypeModal = false;
            this.$refs.form.reset();
        },
        async addCategory () {
            const newCategory = {
                name: this.categoryName,
                id: dti.makeuuid()
            };

            const results = await this.sendCommand({
                action: 'addLaborCategory',
                parameters: newCategory
            });

            if (results) {
                this.message = `Added new labor category`;
                this.laborCategories.push(newCategory);
                this.categoryName = '';
                this.showNewCategoryModal = false;
            } else {
                this.message = `Failed to add category`;
            }
        },
        editLaborType (laborType) {
            this.originalLaborType = cloneDeep(laborType);
            this.editingType = laborType;
            this.showEditModal = true;
        },
        editCategory (category) {
            this.originalCategory = cloneDeep(category);
            this.editingCategory = category;
            this.showCategoryEditModal = true;
        },
        isEditingType (laborType) {
            return this.editingType?.id === laborType.id;
        },
        cancelLaborTypeEdit () {
            Object.assign(this.editingType, this.originalLaborType);
            this.editingType = null;
            this.showEditModal = false;
        },
        cancelCategoryEdit () {
            Object.assign(this.editingCategory, this.originalCategory);
            this.editingCategory = null;
            this.showCategoryEditModal = false;
        },
        getCategory (categoryId) {
            return this.allCategories.find(cat => cat.id === categoryId)?.name;
        },
        deleteLaborType () {
            this.editingType.active = false,
            this.updateLaborType();
        },
        restoreItem (laborType) {
            this.editingType = laborType;
            this.editingType.active = true;
            this.updateLaborType();
        },
        deleteCategory () {
            this.editingCategory.active = false,
            this.updateCategory();
        },
        restoreCategory (category) {
            this.editingCategory = category;
            this.editingCategory.active = true;
            this.updateCategory();
        },
        async updateCategory () {
            const result = await this.sendCommand({
                action: 'updateLaborCategory',
                parameters: this.editingCategory
            });

            if (result) {
                const categoryToEdit = this.allCategories.find((t) => {
                    return t.id === result.id;
                });
                if (categoryToEdit) {
                    categoryToEdit.name = this.editingCategory.name;
                }
                this.showCategoryEditModal = false;
                this.editingCategory = null;
                this.message = `"${categoryToEdit.name}" updated`;
            } else {
                console.log('error updating labor category');
                this.message = `Error updating "${this.editingCategory.name}"`;
            }
        },
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            }
        },
        laborTypes () {
            if (this.showDeleted) {
                return this.allTypes;
            }
            return this.allTypes.filter(type => type.active);
        },
        headers () {
            const headers = [
                {
                    text: 'Cost Item',
                    value: 'cost_item',
                    sortable: true,
                    width: 125
                },
                {
                    text: 'Category',
                    value: 'category',
                    sortable: true,
                    width: 130
                },
                {
                    text: 'Name',
                    value: 'name',
                    sortable: true
                },
                {
                    text: 'Tasks',
                    value: 'description',
                    sortable: true
                },
                {
                    text: '',
                    value: 'edit',
                    sortable: false
                }
            ];
            if (this.showDeleted) {
                headers.push({
                    text: 'Deleted',
                    value: 'deleted'
                });
            }
            return headers;
        },
        categoryHeaders () {
            const headers = [
                {
                    text: 'Name',
                    value: 'name'
                },
                {
                    text: '',
                    value: 'edit',
                    width: 100
                }
            ];
            if (this.showDeletedCategories) {
                headers.push({
                    text: 'Deleted',
                    value: 'deleted'
                });
            }
            return headers;
        },
        laborCategories () {
            if (this.showDeletedCategories) {
                return this.allCategories;
            }
            return this.allCategories.filter(cat => cat.active);
        }
    },
    mounted (){
        this.socketEmit('getLaborRates', null, (results) => {
            this.allTypes = results.laborTypes;
            this.allCategories = results.laborCategories;
        });
        window.satlabortypesvm = this;
    }
};

</script>

<style lang="scss">
.laborTypes {
    .laborTypesTable {
        td {
            position: relative;
        }
        .costItemInput {
            width: 100px;
        }
        .nameInput {
            width: 300px;
        }
    }
}
</style>
